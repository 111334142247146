import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { Find } from '../widgets/Store'

/* Helpers */
import { withRouter } from '../helpers'



/* Page Search */
class Search extends Component {

    render = () => {

        const { params } = this.props
        const text = params.slug ? params.slug : ''

        return (
            <Block text={text} search>
                <Find />
            </Block>
        )
    }
}

export default withRouter(Search)