import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"


/* CSS  */
import './design/css/app.css'
import './design/css/header.css'
import './design/css/footer.css'
import './design/css/home.css'
import './design/css/slide.css'
import './design/css/layout.css'
import './design/css/product.css'
import './design/css/cart.css'
import './design/css/auth.css'
import './design/css/order.css'
import './design/css/address.css'
import './design/css/profile.css'
import './design/css/favourite.css'
import './design/css/products.css'
import './design/css/mobile.css'
import './design/css/orderview.css'
import './design/css/same.css'


/* Redux */
import { Provider } from 'react-redux'
import { store } from './store'

/* Pages */
import { Home, Cart, Order, Category, Profile, Password, Address, Favourite, MyOrders, Product, Search, OrderView, Contact, About, Privacy } from './pages'
import { Account, MobileProfile, MobilePassword, MobileOrders, MobileOrder, MobileAddress, MobileContact, MobileFavourite } from './pages/Account'
import { MobileCart, MobileCheckout, MobileCategory, MobileCategories, MobileSearch } from './pages/Store'


/* Components */
import { Network, Block } from './components'

/* Widgets */
import { Authorization } from './widgets'


/*
    Entry Point
*/
class App extends Component {

    render = () => {

        return (
            <Provider store={store}>
                <Router>
                    <Routes>

                        <Route path="*" element={<Block><Network notfound /></Block>} />

                        <Route path="/" element={<Home />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/order" element={<Order />} />
                        <Route path="/favourite" element={<Favourite />} />
                        <Route path="/search/:slug" element={<Search />} />

                        <Route path="/category/:slug" element={<Category />} />
                        <Route path="/product/:slug" element={<Product />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/privacy-policy" element={<Privacy />} />

                        <Route path="/profile" element={<Profile />} />
                        <Route path="/my-orders" element={<MyOrders />} />
                        <Route path="/order/:number" element={<OrderView />} />
                        <Route path="/address" element={<Address />} />
                        <Route path="/change-password" element={<Password />} />

                        {/* Mobile Screens */}
                        <Route path="/account" element={<Account />} />
                        <Route path="/account/profile" element={<MobileProfile />} />
                        <Route path="/account/password" element={<MobilePassword />} />
                        <Route path="/account/orders" element={<MobileOrders />} />
                        <Route path="/account/order/:number" element={<MobileOrder />} />
                        <Route path="/account/address" element={<MobileAddress />} />
                        <Route path="/account/contact" element={<MobileContact />} />
                        <Route path="/favourite/mobile" element={<MobileFavourite />} />
                        <Route path="/cart/mobile" element={<MobileCart />} />
                        <Route path="/cart/order" element={<MobileCheckout />} />
                        <Route path="/category/mobile" element={<MobileCategory />} />
                        <Route path="/category/mobile/:slug" element={<MobileCategory />} />
                        <Route path="/category/mobile/products/:slug" element={<MobileCategories />} />
                        <Route path="/category/search/:slug" element={<MobileSearch />} />

                    </Routes>

                    <Authorization />

                </Router>
            </Provider>
        )
    }

}

export default App