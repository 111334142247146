import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../../helpers'

/* React Router */
import { Link } from 'react-router-dom'


/* Widget Categories */
class Categories extends Component {

    constructor() {
        super()

        this.state = {
            active: -1
        }
    }

    /* ACtion Go To Category */
    goCategory = (category, index) => {

        const { navigate } = this.props
        const { active } = this.state

        const children = JSON.parse(category.children)

        if (Array.isArray(children) && children.length > 0) {
            if (active !== index) {
                this.setState({ active: index })
            }
            else {
                this.setState({ active: -1 })
            }
        }
        else {
            if (category.slug) {
                navigate(`/category/${category.slug}`)
            }
        }
    }


    render = () => {

        const { data } = this.props
        const { active } = this.state

        return (
            <div className="home-categories">

                <h2>Категория</h2>
                <div className="home-category-line" />

                <div className="home-category-box">
                    {Array.isArray(data) && data.length > 0 && data.map((category, index) => {

                        const children = JSON.parse(category.children)

                        return (
                            <div onClick={() => this.goCategory(category, index)} key={`${index}`} className={`home-category-item ${active === index ? 'active' : ''}`}>

                                <div className="home-category-item-inner">
                                    <img src="/images/arrow.png" alt="Arrow" />
                                    <span>{category.name}</span>
                                </div>

                                <div className="home-category-item-children">
                                    {Array.isArray(children) && children.length > 0 && children.map((child, i) => {
                                        if (child.slug) {
                                            return (
                                                <Link to={`/category/${child.slug}`} key={`${i}`} className="home-category-item-child">
                                                    <span>{child.name}</span>
                                                </Link>
                                            )
                                        }
                                        else {
                                            return <div key={`${i}`} />
                                        }
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        )
    }

}

export default withRouter(Categories)