import axios from 'axios'
import { env } from '../constants'


/* VIEW */
export const view = data => {
    const token = localStorage.getItem("token")
    const headers = token ? { headers: { Authorization: `Bearer ${token}` } } : {}
    const uri = `${env.startpoint}product/get`
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* CATEGORY */
export const category = data => {
    const token = localStorage.getItem("token")
    const headers = token ? { headers: { Authorization: `Bearer ${token}` } } : {}
    const uri = `${env.startpoint}product/category`
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* CATEGORY */
export const withCategory = data => {
    const token = localStorage.getItem("token")
    const headers = token ? { headers: { Authorization: `Bearer ${token}` } } : {}
    const uri = `${env.startpoint}product/with-category`
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* SEARCH */
export const search = data => {
    const token = localStorage.getItem("token")
    const headers = token ? { headers: { Authorization: `Bearer ${token}` } } : {}
    const uri = `${env.startpoint}product/search`
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}