import axios from 'axios'
import { env } from '../constants'


/* HOME DATA */
export const data = () => {
    const token = localStorage.getItem("token")
    const headers = token ? { headers: { Authorization: `Bearer ${token}` } } : {}
    const uri = `${env.startpoint}home/data`
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}