import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../../helpers'

/* Constants */
import { env } from '../../constants'



/* Widget Banner */
class Banner extends Component {

    render = () => {

        const { navigate, data } = this.props

        if (data === null || data === undefined || !Array.isArray(data) || data.length === 0) {
            return <div />
        }

        return (
            <div className="slider">

                {/* SMALL SLIDES */}
                <div className="slides">

                    {data.map((item, index) =>
                        <div onClick={() => navigate(item.link)} className="slide-item" key={`${index}`}>
                            {item.image ? <img src={`${env.mediapoint}banners/${item.image}`} alt="Slide" /> : null}

                            <div className="slide-item-box">
                                <div className="slide-item-box-sale">{item.text}</div>
                                <p>{item.title}</p>
                            </div>
                            <div className="slide-item-button">Подробнее</div>
                        </div>
                    )}
                </div>

            </div>
        )
    }

}

export default withRouter(Banner)