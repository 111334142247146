import React, { Component } from "react"

/* Components */
import { Loading, Network } from "../../components"

/* Widgets */
import { Product } from '../Product'

/* REST API */
import { search } from '../../api/Product'

/* Helpers */
import { withRouter } from '../../helpers'



/* Widget Find */
class Find extends Component {

    constructor() {
        super()

        this.state = {

            data: [],

            loading: true,
            error: false,
            network: false
        }

        this._time = null
    }

    componentDidMount = () => {
        this.load()

        window.addEventListener("scroll", this.handleScroll, { passive: true })
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.handleScroll)

        if (this._time) {
            clearTimeout(this._time)
            this._time = null
        }

    }

    handleScroll = () => {

        if (this._time) {
            clearTimeout(this._time)
            this._time = null
        }

        this._time = setTimeout(() => {
            localStorage.setItem("tempSearchScrollOffset", window.scrollY)
        }, 200)

    }


    componentDidUpdate = (prevProps) => {

        const { params } = this.props

        if (params.slug !== prevProps.params.slug) {
            this.load(params.slug)
        }

    }


    load = (slug = '') => {

        const { params } = this.props

        search({ slug: slug ? slug : params.slug }).then(response => {
            if (response.status === 200) {

                const scrollY = localStorage.getItem("tempSearchScrollOffset")

                setTimeout(() => {
                    window.scroll({ top: parseInt(scrollY), left: 0, behavior: "smooth" })
                    localStorage.removeItem("tempSearchScrollOffset")
                }, 100)

                this.setState({ data: response.data, network: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }



    render = () => {

        const { loading, error, network, data } = this.state
        const { navigate, params } = this.props


        if (loading) {
            return <Loading />
        }

        if (error) {
            return <Network error reload={() => this.load()} />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }

        return (
            <div className="search">
                <div className="search-wrapper">

                    <div className="search-title">
                        <div onClick={() => navigate(-1)} className="search-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>Поиск по запросу "{params.slug}"</h2>
                    </div>

                    <div className="search-box">
                        {data.map((item, index) =>
                            <div className="search-product" key={`${index}`}>
                                <Product data={item} />
                            </div>
                        )}
                        {data.length === 0 && <div className="search-products-empty">По вашему запросу ничего не найдено</div>}
                    </div>

                </div>
            </div>
        )
    }

}

export default withRouter(Find)