import axios from "axios"
import { env } from '../constants'


/* CREATE */
export const create = (token, data) => {
    const uri = `${env.startpoint}order/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* LIST */
export const list = token => {
    const uri = `${env.startpoint}order/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* VIEW */
export const view = (token, data) => {
    const uri = `${env.startpoint}order/view`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* VIEW */
export const user = token => {
    const uri = `${env.startpoint}order/user`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}
