import React, { useEffect, useState } from "react"

/* Swipeable */
import { useSwipeable } from 'react-swipeable'

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from '../../constants'


/* Constants */
const MULTIPLIER = 2.5


/* Widget Slide */
const Slide = ({ data }) => {

    /* Hooks */
    const [width, setWidth] = useState(window.innerWidth)
    const [index, setIndex] = useState(0)
    const [paused, setPaused] = useState(false)

    /* Swipe Handlers */
    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(index + 1, width),
        onSwipedRight: () => updateIndex(index - 1, width),
    })

    /* Window Resize Events and Auto Play */
    useEffect(() => {

        const interval = setInterval(() => {
            if (!paused) {
                updateIndex(index + 1, width)
            }
        }, 5000)

        window.addEventListener('resize', updateWindowDimensions)

        return () => {

            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', updateWindowDimensions)
        }

    })


    /* Window Resize Handler */
    const updateWindowDimensions = () => {
        setWidth(window.innerWidth)
    }


    /* Change Carousel Index */
    const updateIndex = (i) => {

        if (Array.isArray(data) && data.length > 1) {

            if (i < 0) {
                i = data.length - 1
            }
            else if (i >= data.length) {
                i = 0
            }

            setIndex(i)

        }
    }


    let carouselWidth = width - 40 - 350

    if (width >= 1200) {
        carouselWidth = 850
    }

    if (width <= 768) {
        carouselWidth = width - 40
    }


    return (
        <div className="home-slide-container">
            <div className="home-slide" {...handlers} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>

                <div className="home-slide-inner" style={{ transform: `translateX(${- carouselWidth * index}px)` }}>
                    {Array.isArray(data) && data.length > 0 && data.map((item, i) =>
                        <div className="home-slide-item" style={{ width: carouselWidth, height: carouselWidth / MULTIPLIER }} key={`${i}`}>
                            <Link to={`/${item.link ? item.link : ''}`} className="home-slide-item-inner">

                                {item.image ? <img src={`${env.mediapoint}slides/${item.image}`} alt="Slide" /> : null}

                                <div className="home-slide-item-text">
                                    <h2>{item.title}</h2>
                                    <span>{item.text}</span>
                                    <div className="home-slide-item-button">Купить сейчас</div>
                                </div>

                            </Link>
                        </div>
                    )}
                </div>
            </div>

            {Array.isArray(data) && data.length > 1 &&
                <div onClick={() => updateIndex(index + 1, width)} className="home-slide-arrow" style={{ top: carouselWidth / MULTIPLIER / 2 - 15, right: -15 }}>
                    <img src="/images/arrowleft.png" alt="Arrow" />
                </div>
            }

            {Array.isArray(data) && data.length > 1 &&
                <div onClick={() => updateIndex(index - 1, width)} className="home-slide-arrow" style={{ top: carouselWidth / MULTIPLIER / 2 - 15, left: -15 }}>
                    <img src="/images/arrowright.png" alt="Arrow" />
                </div>
            }

        </div>
    )

}


export default Slide