import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* REST API */
import { list } from "../api/Category"


/*
    Widget Footer
*/
class Footer extends Component {


    constructor() {
        super()

        this.state = {
            data: [],
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        list().then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.categories })
            }
        })

        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }


    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }


    render = () => {

        const { data, width } = this.state

        return (
            <div className="footer">
                <div className="footer-wrapper">


                    {/* FOOTER BOX */}
                    <div className="footer-box">

                        <div className="footer-item footer-main">

                            {/* FOOTER LOGO */}
                            <div className="footer-logo">
                                <Link to="/">
                                    <img src="/images/logo.png" alt="T&N Group" />
                                </Link>
                            </div>

                            <p>
                                Наша миссия – обеспечить вас надежными компонентами, которые подарят вашим грузовикам новую жизнь на дороге.
                            </p>

                            <div className="footer-info">
                                <img src="/images/footer/home.png" alt="Home" />
                                <b>Казахстан, г. Алматы, ​проспект Рыскулова, 72/5​</b>
                            </div>

                            <div className="footer-info">
                                <img src="/images/footer/mail.png" alt="Mail" />
                                <b>tngroup2021@mail.ru</b>
                            </div>

                        </div>

                        <div className="footer-item">
                            <span>Категории</span>
                            {data.map((item, index) => {
                                if (index < 6) {
                                    return <Link key={`${index}`} to={width > 768 ? `/category/${item.slug}` : parseInt(item.amount) > 0 ? `/category/mobile/${item.slug}` : `/category/mobile/products/${item.slug}`}>{item.name}</Link>
                                }
                                else {
                                    return <div key={`${index}`} />
                                }
                            })}
                        </div>

                        <div className="footer-item">
                            <span>Полезные ссылки</span>
                            <Link>Главная</Link>
                            <Link to="/about">О нас</Link>
                            <Link to={width > 748 ? '/contact' : '/account/contact'}>Контакты</Link>
                        </div>

                        <div className="footer-item">

                            <span>Связаться с нами</span>

                            <div className="footer-item-contact">
                                <img src="/images/footer/phone.png" alt="Phone" />
                                <div className="footer-item-contact-name">
                                    <div className="footer-item-contact-text">Будние дни 09:00 - 18:00</div>
                                    <div className="footer-item-contact-text">Cуббота 09:00 - 16:00</div>
                                    <div className="footer-item-contact-value">+7 (775) 392-03-43</div>
                                </div>
                            </div>
                            <div className="footer-item-contact">
                                <img src="/images/footer/mail.png" alt="Mail" />
                                <div className="footer-item-contact-name">
                                    <div className="footer-item-contact-text">Email Address:</div>
                                    <div className="footer-item-contact-value">tngroup2021@mail.ru</div>
                                </div>
                            </div>

                        </div>

                    </div>


                    {/* COPY BOX */}
                    <div className="copy">
                        <p>© 2023 «T&N group» Все права защищены.</p>
                        <img src="/images/footer/visa.png" alt="Visa" />
                        <div className="social-network">
                            <p>Оставайся на связи:</p>
                            <div className="social-network-box">
                                <a target="_blank" rel="noreferrer" href="https://go.2gis.com/1lgj7"><img src="/images/social/2gis.png" alt="2GIS" /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tootngroup/"><img src="/images/social/instagram.png" alt="Instagram" /></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default Footer