import React, { Component } from "react"

/* Helpers */
import { utils, withRouter } from '../helpers'

/* Redux */
import { connect } from 'react-redux'

/* REST API */
import { view } from '../api/Product'

/* Components */
import { Block, Loading, Network, Picture } from "../components"

/* Constants */
import { env } from "../constants"

/* Swal */
import Swal from 'sweetalert2'

/* Share */
import { WhatsappShareButton, WhatsappIcon, TelegramIcon, TelegramShareButton } from 'react-share'

/* Copy */
import { CopyToClipboard } from 'react-copy-to-clipboard'

/* Widgets */
import { Heart, Product as ProductBox } from '../widgets/Product'

/* Document */
import DocumentMeta from 'react-document-meta'

/* Modal */
import Modal from 'react-modal'





/* Page Product */
class Product extends Component {


    constructor() {
        super()

        this.state = {
            data: null,

            loading: true,
            error: false,
            network: false,
            width: 0,

            image: null,
            index: -1,

            zoom: false,
            imageLoading: false
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        window.scroll(0, 0)
        this.load()
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }

    componentDidUpdate = (prevProps) => {
        const { params } = this.props
        if (params.slug !== prevProps.params.slug) {
            this.load(params.slug, true)
        }
    }



    /* LOAD PRODUCT DATA */
    load = (slug = '', reload = false) => {

        if (reload) {
            this.setState({ loading: true, imageLoading: false })
        }

        /* FIELDS */
        const { params } = this.props

        view({ slug: slug !== '' ? slug : params.slug }).then(response => {
            if (response.status === 200) {
                const data = response.data
                this.setState({ data: data, image: data.image, network: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    /* Add to Cart */
    add = () => {

        /* Fields */
        const { add } = this.props
        const { data } = this.state

        if (parseInt(data.remaining) > 0) {

            /* Calculations */
            const payload = { ...data, amount: 1 }

            /* SAVE IN STATE */
            add(payload)

            /* Notify */
            Swal.fire({
                html: `<p class="notification">Успешно добавлено в корзину</p>`,
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })

        }
        else {

            /* Notification */
            Swal.fire({
                html: `<p class="notification">Товара нет в наличии</p>`,
                color: 'white',
                background: '#f44336',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })
        }
    }


    /* Plus */
    plus = amount => {

        /* Fields */
        const { update } = this.props
        const { data } = this.state

        /* Calculations */
        const count = amount + 1
        const payload = { ...data, amount: count }

        if (parseInt(data.remaining) < count) {
            Swal.fire({
                html: `<p class="notification">В наличии ${data.remaining} шт</p>`,
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })
            return
        }

        /* SAVE IN STATE */
        update(payload)
    }


    /* Change value */
    change = amount => {

        let count = parseInt(amount)

        if (amount === "") {
            count = 0
        }

        if (!Number.isInteger(count)) {
            return
        }

        /* Fields */
        const { update, remove } = this.props
        const { data } = this.state

        /* Calculations */
        const payload = { ...data, amount: count }

        if (parseInt(data.remaining) < count) {
            Swal.fire({
                html: `<p class="notification">В наличии ${data.remaining} шт</p>`,
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })
            return
        }

        /* SAVE IN STATE */
        if (count === 0) {
            remove(payload)
        }
        else {
            update(payload)
        }
    }


    /* Minus */
    minus = amount => {

        /* Fields */
        const { update, remove } = this.props
        const { data } = this.state

        /* Calculations */
        if (amount - 1 >= 0) {

            const count = amount - 1
            const payload = { ...data, amount: count }

            /* SAVE IN STATE */
            if (count === 0) {
                remove(payload)
            }
            else {
                update(payload)
            }

        }
    }


    /* Draw button */
    _button = () => {

        /* Fields */
        const { cart, navigate } = this.props
        const { data } = this.state

        let amount = 0

        /* FIND PRODUCT AMOUNT IN CART */
        if (data) {
            if (Array.isArray(cart) && cart.length > 0) {
                const index = cart.findIndex(item => parseInt(item.id) === parseInt(data.id))
                if (index > -1) {
                    amount = cart[index].amount
                }
            }
        }

        /* DISPLAY COUNTER BUTTONS */
        return (
            <div className="product-view-card-counter-box">

                {/* Counter */}
                <div className="product-view-card-counter">

                    {/* Minus */}
                    <div onClick={() => this.minus(amount)} className="product-view-card-minus-button">
                        <img src="/images/minus-filled.png" alt="Minus" />
                    </div>

                    {/* Amount */}
                    <input className="product-view-amount" inputMode="decimal" value={String(amount)} onChange={event => this.change(event.target.value)} />

                    {/* Plus */}
                    <div onClick={() => this.plus(amount)} className="product-view-card-plus-button">
                        <img src="/images/plus-filled.png" alt="Plus" />
                    </div>

                </div>

                {/* Cart navigator */}
                <div onClick={() => navigate("/cart")} className="product-view-card-button">
                    Перейти в корзину
                </div>

            </div>
        )

    }


    /* Copy */
    copy = () => {
        Swal.fire({
            html: `<p class="notification">Ссылка успешна скопирована</p>`,
            color: 'white',
            background: 'black',
            showConfirmButton: false,
            position: 'bottom-left',
            timer: 1000,
            backdrop: false,
            width: 300
        })
    }


    /* Draw Share buttons */
    _share = () => {

        /* Fields */
        const { data } = this.state
        const url = `${env.link}product/${data.slug}`

        return (
            <div className="product-view-social-networks">

                {/* Link */}
                <CopyToClipboard text={url}>
                    <div onClick={() => this.copy()} className="product-view-social-copy">
                        <img src="/images/link.png" alt="Link" />
                    </div>
                </CopyToClipboard>

                {/* Whatsapp */}
                <WhatsappShareButton url={url} className="product-view-social-item">
                    <WhatsappIcon size={36} round />
                </WhatsappShareButton>

                {/* Telegram */}
                <TelegramShareButton url={url} className="product-view-social-item">
                    <TelegramIcon size={36} round />
                </TelegramShareButton>

            </div>
        )
    }


    /* Draw Same Products */
    _same = () => {

        const { data } = this.state

        if (data && data.same && Array.isArray(data.same) && data.same.length > 0) {
            return (
                <div className="same-products">

                    <h2>Похожие товары</h2>

                    <div className="same-product-container">
                        {data.same.map((product, index) =>
                            <div key={`${index}`} className="same-product-item" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                                <ProductBox data={product} />
                            </div>
                        )}
                    </div>

                </div>
            )
        }
    }


    /* Zoom */
    _zoom = () => {

        const { zoom, data, image } = this.state

        return (
            <Modal
                isOpen={zoom}
                onRequestClose={() => this.setState({ zoom: false })}
                className="zoom-modal"
                overlayClassName="zoom-overlay"
            >

                <div onClick={() => this.setState({ zoom: false })} className="zoom-close">
                    <img src="/images/x.png" alt="X" />
                </div>

                <img onClick={() => this.setState({ zoom: false })} src={`${env.mediapoint}products/${image}`} alt={data.name} />

            </Modal>
        )
    }


    _images = () => {

        const { data, index } = this.state

        if (data.images) {
            const images = JSON.parse(data.images)

            if (Array.isArray(images) && images.length > 0) {
                return (
                    <div className="product-view-gallery">

                        <div onClick={() => this.setState({ index: -1, image: data.image })} className={`product-view-gallery-item ${index === -1 ? 'active' : ''}`}>
                            <Picture uri={`${env.mediapoint}products/${data.image}`} />
                        </div>

                        {images.map((item, i) =>
                            <div onClick={() => this.setState({ index: i, image: item.image })} className={`product-view-gallery-item ${index === i ? 'active' : ''}`} key={`${i}`}>
                                <img src={`${env.mediapoint}products/${item.image}`} alt={data.name} />
                            </div>
                        )}
                    </div>
                )
            }
        }
    }


    render = () => {

        const { navigate } = this.props
        const { data, loading, error, network, width, image, imageLoading } = this.state


        if (loading) {
            return <Loading />
        }

        if (error || !data) {
            return <Network error reload={() => this.load()} />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }

        const content = (
            <div className="product-view">
                <div className="product-view-wrapper">

                    <div className="product-view-title">
                        <div onClick={() => navigate(-1)} className="product-view-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>{data.name}</h2>
                    </div>

                    {this._share()}

                    <div className="product-view-box">

                        <div className="product-view-image">

                            <img onClick={() => this.setState({ zoom: true })} src={`${env.mediapoint}products/${image}`} alt={data.name} onLoad={() => this.setState({ imageLoading: true })} style={{ opacity: imageLoading ? 1 : 0 }} />
                            <img onClick={() => this.setState({ zoom: true })} src="/images/spare-parts.png" alt={data.name} style={{ opacity: imageLoading ? 0 : 1 }} className="default" />

                            {/* Like */}
                            <Heart data={data} />

                            {this._images()}

                        </div>

                        <div className="product-view-information">

                            <h1>{data.name}</h1>

                            {(data.remaining !== undefined && data.remaining !== null) &&
                                parseInt(data.remaining) > 0 ? <div className="product-view-available green">В наличии есть: {parseInt(data.remaining)} шт </div> : <div className="product-view-available red">В наличии нет</div>
                            }

                            {(data.code !== null && data.code !== "") && <p>Код товара: {data.code}</p>}
                            {(data.article !== null && data.article !== "") && <p>Артикул: {data.article}</p>}
                            {(data.description !== null && data.description !== "") && <p>{data.description}</p>}

                            <div className="product-view-price">
                                <b>Стоимость:</b>
                                <span>{utils.convertor(data.price)}</span>
                            </div>
                            {this._button()}
                        </div>
                    </div>


                    {/* Same Products */}
                    {this._same()}

                </div>

                {this._zoom()}
            </div>
        )


        const meta = {
            title: `${data.name} | T&N Group`,
            description: data.metaDescription,
            canonical: `${env.link}product/${data.slug}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: data.metaKeywords
                }
            }
        }


        if (width > 768) {
            return (
                <DocumentMeta {...meta}>
                    <Block>
                        {content}
                    </Block>
                </DocumentMeta>
            )
        }


        return (
            <DocumentMeta {...meta}>
                <div className="mobile-account-page">

                    <div onClick={() => navigate(-1)} className="account-header-close">
                        <img src="/images/mobile/down.png" alt="Back" />
                    </div>

                    {content}

                </div>
            </DocumentMeta>
        )
    }

}


const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token
    }
}


const mapDispatchToProps = dispatch => {
    return {
        add: data => dispatch({ type: 'ADD_TO_CART', payload: data }),
        update: data => dispatch({ type: 'UPDATE_DATA', payload: data }),
        remove: data => dispatch({ type: 'REMOVE_FROM_CART', payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product))
