import React, { Component } from "react"

/* Widgets */
import { Categories, Slide, Banner, Top, Products } from '../widgets/Home'

/* REST API */
import { data } from '../api/Home'

/* Components */
import { Block, Loading, Network } from '../components'


/*
    Page Home
*/
class Home extends Component {

    constructor() {
        super()

        this.state = {
            data: null,
            loading: true,
            network: false,
            error: false
        }
    }

    componentDidMount = () => {
        window.scroll(0, 0)
        this.load()
    }


    /* Load Home Page Data */
    load = () => {
        data().then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, error: false, network: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ error: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    render = () => {

        const { loading, network, error, data } = this.state

        if (loading) {
            return (
                <Block>
                    <Loading />
                </Block>
            )
        }

        if (error || !data) {
            return (
                <Block>
                    <Network error reload={() => this.load()} />
                </Block>
            )
        }

        if (network) {
            return (
                <Block>
                    <Network reload={() => this.load()} />
                </Block>
            )
        }

        return (
            <Block>
                <div className="home">

                    <div className="home-wrapper">

                        <Categories data={data.categories} />

                        <div className="home-content">
                            {data.slides && <Slide data={data.slides} />}
                            {data.banners && <Banner data={data.banners} />}
                            {data.top && <Top data={data.top} />}
                            {data.all && <Products data={data.all} />}
                        </div>

                    </div>

                </div>
            </Block>
        )
    }

}

export default Home