import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Redux */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter } from '../helpers'

/* JWT */
import { isExpired, decodeToken } from "react-jwt"

/* Constants */
import { env } from "../constants"

/* REST API */
import { search } from '../api/Product'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/*
    Widget Header
*/
class Header extends Component {

    constructor(props) {
        super(props)

        const { text } = props
        let search = ''

        if (text) {
            search = text
        }

        this.state = {
            search,
            result: [],
            loading: false
        }

        this._timeout = 0
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.sticky)
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.sticky)
    }


    /* Scroll Event Listener */
    sticky = () => {
        const header = document.querySelector('.header-box')
        const top = document.querySelector('.header-top')
        const scrollTop = window.scrollY
        scrollTop >= 40 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky')
        scrollTop >= 40 ? top.classList.add('header-big') : top.classList.remove('header-big')
    }


    /* Draw Top Part */
    _top = () => {

        const { city, cities, setCity, navigate } = this.props

        return (
            <div className="header-top">
                <div className="header-top-wrapper">

                    {/* MOBILE LOGO */}
                    <div onClick={() => navigate("/")} className="mobile-logo">
                        <img src="/images/logo-white.png" alt="Logo" />
                    </div>

                    {/* HEADER CITY */}
                    {city &&
                        <div className="header-top-city">
                            <img src="/images/mobile/pin.png" alt="Location" />
                            <span>{city.name}</span>

                            <div className="header-top-city-list">
                                {cities.map((item, index) =>
                                    <div onClick={() => setCity(item)} key={`${index}`} className={parseInt(item.id) === parseInt(city.id) ? "header-top-city-item active" : "header-top-city-item"}>{item.name}</div>
                                )}
                            </div>

                        </div>
                    }

                    {/* SHOP ADDRESS */}
                    <div className="header-top-address">
                        Казахстан, г. Алматы, ​проспект Рыскулова, 72/5
                    </div>

                    {/* LANGUAGE */}
                    <div className="header-top-language">
                        <img src="/images/language.png" alt="Language" />
                        <span>Русский</span>
                    </div>

                </div>
            </div>
        )
    }


    /* Go To Cart */
    goCart = () => {
        const { cart, navigate } = this.props

        if (cart.length > 0) {
            navigate("/cart")
        }
    }


    /* Go To User */
    goUser = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/profile')
        }
    }

    /* Go To Favourite */
    goFavourite = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/favourite')
        }
    }

    /* Go To Search */
    goSearch = event => {

        event.preventDefault()

        const { navigate } = this.props
        const { search } = this.state

        if (search !== "") {
            navigate(`/search/${search}`)
        }
    }


    /* Search */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {

            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {

                search({ slug: text }).then(response => {
                    if (response.status === 200) {
                        this.setState({ result: response.data })
                    }
                }).finally(() => {
                    this.setState({ loading: false })
                })

            }, 1000)

        }
    }


    _result = () => {

        const { loading, result, search } = this.state
        const { navigate, isSearchPage } = this.props

        if (search !== "" && !isSearchPage) {

            return (
                <div className="header-search-result">
                    {loading && <LoadingOutlined />}
                    {(loading === false && result.length === 0) && <p>По вашему запросу ничего не найдено</p>}
                    {(loading === false && result.length > 0) && result.map((product, index) => {

                        if (index <= 4) {
                            return (
                                <div onClick={() => navigate(`/product/${product.slug}`)} className="header-search-result-item" key={`${index}`}>
                                    {product.name ? <div className="header-search-result-item-name">{product.name}</div> : null}
                                    {product.code ? <div className="header-search-result-item-code">Код товара: {product.code}</div> : null}
                                    {product.article ? <div className="header-search-result-item-article">Артикул: {product.article}</div> : null}
                                </div>
                            )
                        }

                        return null
                    })}
                </div>
            )
        }

    }


    render = () => {

        const { cart, token, city, cities, setCity } = this.props
        const user = token ? decodeToken(token) : null

        const { search } = this.state

        return (
            <div className="header">

                {this._top()}

                <div className="header-box">
                    <div className="header-wrapper">

                        <div className="header-left-box">

                            {/* HEADER LOGO */}
                            <div className="header-logo">
                                <Link to="/">
                                    <img src="/images/logo.png" alt="T&N Group" />
                                </Link>
                            </div>

                            {/* HEADER CITY */}
                            {city &&
                                <div className="header-city">
                                    <img src="/images/location.png" alt="Location" />
                                    <span>{city.name}</span>

                                    <div className="header-city-list">
                                        {cities.map((item, index) =>
                                            <div onClick={() => setCity(item)} key={`${index}`} className={parseInt(item.id) === parseInt(city.id) ? "header-city-item active" : "header-city-item"}>{item.name}</div>
                                        )}
                                    </div>

                                </div>
                            }

                            {/* HEADER SEARCH */}
                            <div className="header-search">

                                <form onSubmit={this.goSearch}>
                                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск по магазину (Название, Артикул, Код товара и т.д)" />

                                    <div onClick={this.goSearch} className="header-search-button">
                                        <img src="/images/search.png" alt="Search" />
                                    </div>
                                </form>

                                {this._result()}
                            </div>

                        </div>

                        <div className="header-right-box">

                            {/* HEADER CONTACT INFORMATION */}
                            <div className="header-contact">
                                <img src="/images/phone.png" alt="Phone" />
                                <div className="header-contact-text">
                                    <span>Будние дни&nbsp; 09:00 - 18:00</span>
                                    <span>Cуббота&nbsp; 09:00 - 16:00</span>
                                    <p>+7 (775) 392-03-43</p>
                                </div>
                            </div>

                            <div className="header-divider" />

                            {/* HEADER FAVOUTRITE */}
                            <div onClick={() => this.goFavourite()} className="header-favourite">
                                <img src="/images/heart.png" alt="Heart" />
                            </div>

                            <div className="header-divider" />

                            {/* HEADER FAVOUTRITE */}
                            <div onClick={() => this.goCart()} className="header-cart">
                                <img src="/images/cart.png" alt="Cart" />
                                {cart.length > 0 && <span>{cart.length}</span>}
                                {cart.length === 0 &&
                                    <div className="header-empty-cart">
                                        <div className="header-cart-corner" />
                                        <img src="/images/empty-cart.png" alt="Empty Cart" />
                                        <div className="header-empty-cart-box">
                                            <h3>Корзина пуста</h3>
                                            <p>Добавьте что-то из категории для оформления заказа</p>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="header-divider" />

                            {/* HEADER USER */}
                            <div onClick={() => this.goUser()} className="header-user">

                                {(user && user.image) ? <img src={`${env.mediapoint}users/${user.image}`} alt="user" className="ava" /> : <img src="/images/user.png" alt="user" />}

                                {user
                                    ? <div className="header-user-text">
                                        <span>Привет</span>
                                        <p>{user.name}</p>
                                    </div>
                                    : <div className="header-user-text">
                                        <span>Мой аккаунт</span>
                                        <p>Войти</p>
                                    </div>
                                }
                            </div>

                        </div>


                    </div>
                </div>

            </div >
        )
    }

}


const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token,
        cities: state.cities,
        city: state.city,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: data => dispatch({ type: 'TOGGLE_MODAL', payload: data }),
        setCity: data => dispatch({ type: 'SET_CITY', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))