import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page About */
class About extends Component {


    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="about">
                    <div className="about-wrapper">
                        <h1>О магазине</h1>


                        <div className="about-box">

                            <div className="about-box-text">

                                <p>
                                    Мы построили международную компанию, с оптовыми складами, розничными магазинами, официальными представителями на территории Казахстана, продолжаем реализацию проектов по обновлению автопарка, производственного оборудования. Но самое главное - мы стали ближе и удобнее для потребителя.
                                </p>

                                <p>
                                    Мы стремимся стать компанией №1 в Казахстане, странах Евросоюза с точки зрения понимания потребителей, производства и реализации для них востребованных высокотехнологичных запасных частей и обеспечения качественного сервиса обслуживания.
                                </p>

                                <p>
                                    Сегодня мы способны сделать качественное и выгодное предложение рынку, которое включает в себя востребованные запасные части для автомобилей HOWO, SHAANX.
                                    Мы стремимся быть лидерами в маркетинге, технологиях и сервисе.
                                </p>

                                <p>
                                    На нашем сайте Вы найдете всю интересующую Вас информацию о нашей компании и о всех запчастях к автомобилям HOWO, SHAANX, которые мы реализуем.
                                </p>

                                <p>
                                    При просмотре необходимых запчастей и составление заявки у Вас не возникнет сложностей. Мы стараемся постоянно поддерживать самые выгодные цены на запчасти, актуальную информацию о сроках доставки, а также режимах работы наших магазинов, официальных представительств.
                                </p>

                                <p>
                                    Мы будем постоянно информировать Вас обо всех новинках нашего ассортимента, а также о действующих акциях и специальных предложениях.
                                </p>

                                <p>
                                    Вы всегда можете обратиться к нам напрямую, задав свои вопросы через удобный и бесплатный сервис «онлайн-консультант», либо отправив сообщение нашим сотрудникам.
                                </p>
                            </div>

                            <img src="/images/about.jpg" alt="About" />

                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default About