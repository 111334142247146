import React, { Component } from "react"

/* Constants */
import { env } from '../../constants'

/* Components */
import { Image } from '../../components'

/* Helpers */
import { utils } from '../../helpers'

/* React Router */
import { Link } from "react-router-dom"

/* Redux */
import { connect } from 'react-redux'

/* Swal */
import Swal from 'sweetalert2'

/* Widgets */
import Heart from "./Heart"



/* Widget Product */
class Product extends Component {


    /* Add to Cart */
    add = () => {

        /* Fields */
        const { data, add } = this.props

        if (parseInt(data.remaining) > 0) {
            
            /* Calculations */
            const payload = { ...data, amount: 1 }

            /* SAVE IN STATE */
            add(payload)

            /* Notification */
            Swal.fire({
                html: `<p class="notification">Успешно добавлено в корзину</p>`,
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })

        }
        else {
            /* Notification */
            Swal.fire({
                html: `<p class="notification">Товара нет в наличии</p>`,
                color: 'white',
                background: '#f44336',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })
        }


    }


    /* Plus */
    plus = amount => {

        /* Fields */
        const { data, update } = this.props

        /* Calculations */
        const count = amount + 1
        const payload = { ...data, amount: count }

        if (parseInt(data.remaining) < count) {
            Swal.fire({
                html: `<p class="notification">В наличии ${data.remaining} шт</p>`,
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'bottom-left',
                timer: 1000,
                backdrop: false,
                width: 300
            })
            return
        }

        /* SAVE IN STATE */
        update(payload)
    }


    /* Minus */
    minus = amount => {

        /* Fields */
        const { data, update, remove } = this.props

        /* Calculations */
        const count = amount - 1
        const payload = { ...data, amount: count }

        /* SAVE IN STATE */
        if (count === 0) {
            remove(payload)
        }
        else {
            update(payload)
        }
    }



    /* Draw BUTTON */
    _button = () => {

        /* Fields */
        const { cart, data } = this.props

        let amount = 0

        /* FIND PRODUCT AMOUNT IN CART */
        if (data) {
            if (Array.isArray(cart) && cart.length > 0) {
                const index = cart.findIndex(item => parseInt(item.id) === parseInt(data.id))
                if (index > -1) {
                    amount = cart[index].amount
                }
            }
        }

        /* DISPLAY BUTTON WHEN AMOUNT IS ZERO */
        if (amount === 0) {
            return (
                <div onClick={() => this.add()} className="product-card-button">

                    В корзину

                    {/* Plus */}
                    <div className="product-card-plus-button">
                        <img src="/images/plus.png" alt="Plus" />
                    </div>

                </div>
            )
        }

        /* DISPLAY COUNTER BUTTONS */
        return (
            <div className="product-card-button">

                {/* Minus */}
                <div onClick={() => this.minus(amount)} className="product-card-minus-button">
                    <img src="/images/minus.png" alt="Minus" />
                </div>

                {amount}

                {/* Plus */}
                <div onClick={() => this.plus(amount)} className="product-card-plus-button">
                    <img src="/images/plus.png" alt="Plus" />
                </div>

            </div>
        )

    }


    render = () => {

        /* Fields */
        const { data } = this.props

        /* CHECK DATA */
        if (data === null || data === undefined) {
            return <div />
        }


        return (
            <div className="product-box">

                {/* Like */}
                <Heart data={data} />

                <Link to={`/product/${data.slug}`}>

                    {/* Image */}
                    <Image uri={`${env.mediapoint}products/${data.image}`} />

                    {/* Info */}
                    <div className="product-info">
                        <div className="product-name">{data.name}</div>
                        {data.code ? <div className="product-code">Код {data.code}</div> : ''}
                        {data.article ? <div className="product-article">Артикул {data.article}</div> : ''}

                        <div className="product-price-box">
                            <div className="product-price">{utils.convertor(data.price)}</div>
                        </div>
                        {data.remaining === null || data.remaining === undefined || parseInt(data.remaining) === 0 ? <div className="product-remaining">В наличии нет</div> : <div className="product-remaining green">В наличии {parseInt(data.remaining)} шт </div>}
                    </div>

                </Link>

                {this._button()}

            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        add: data => dispatch({ type: 'ADD_TO_CART', payload: data }),
        update: data => dispatch({ type: 'UPDATE_DATA', payload: data }),
        remove: data => dispatch({ type: 'REMOVE_FROM_CART', payload: data }),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Product)