import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page Contact */
class Contact extends Component {

    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="contact">
                    <div className="contact-wrapper">

                        <h2>Контакты</h2>

                        <div className="mobile-contact">
                            <div className="mobile-contact-item">

                                <h3>г. Алматы</h3>

                                <p>Для партеров</p>
                                <b>+7 (775) 392-03-43</b>

                                <p>Отдел продаж:</p>
                                <b>+7 (775) 392-03-43</b>

                                <p>Вопросы, отзывы и предложения:</p>
                                <b>support@tngroup.kz</b>

                                <div className="mobile-contact-line" />

                                <p>Адрес:</p>
                                <b>Проспект Рыскулова, 72/5</b>

                            </div>
                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default Contact